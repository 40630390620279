import { useState } from 'react';

export function useGlobalStatesStore() {
  const [openSiteHomeModal, setOpenSiteHomeModal] = useState();
  const [openPromoModal, setOpenPromoModal] = useState();
  const [openPromoModalTeacher, setOpenPromoModalTeacher] = useState();
  const [openFeedback, setOpenFeedback] = useState(false);
  const [openPromoSuccess, setOpenPromoSuccess] = useState();
  const [openValidationTooltip, setOpenValidationTooltip] = useState({
    email: false,
    password: false,
    confirmPassword: false,
    cpf: false,
    cpfExists: false,
    name: false,
    fullName: false,
    phone: false,
    objective: false,
    englishLevel: false,
  });
  const [studentBackupRequestId, setStudentBackupRequestId] = useState();
  const [showAvailable, setShowAvailable] = useState({
    email: false,
    cpf: false,
  });
  const [showUnavailable, setShowUnavailable] = useState({
    email: false,
    cpf: false,
  });
  const [openPlanSignature, setOpenPlanSignature] = useState();
  const [openAnniversaryModal, setOpenAnniversaryModal] = useState();
  const [openContentAreaModal, setOpenContentAreaModal] = useState(false);
  const [hasAccessToContent, setHasAccessToContent] = useState(false);
  const [endAccount, setEndAccount] = useState();
  const [reasonsStep, setReasonsStep] = useState({
    time: 1,
    financial: 1,
    vacation: 1,
    other: 1,
    success: {
      promo: false,
      request: false,
    },
  });

  return {
    openSiteHomeModal,
    setOpenSiteHomeModal,
    openPromoModal,
    setOpenPromoModal,
    openPromoSuccess,
    setOpenPromoSuccess,
    openPromoModalTeacher,
    setOpenPromoModalTeacher,
    openValidationTooltip,
    setOpenValidationTooltip,
    openFeedback,
    setOpenFeedback,
    studentBackupRequestId,
    setStudentBackupRequestId,
    showUnavailable,
    setShowUnavailable,
    showAvailable,
    setShowAvailable,
    openPlanSignature,
    setOpenPlanSignature,
    openAnniversaryModal,
    setOpenAnniversaryModal,
    endAccount,
    setEndAccount,
    reasonsStep,
    setReasonsStep,
    openContentAreaModal,
    setOpenContentAreaModal,
    hasAccessToContent,
    setHasAccessToContent,
  };
}
