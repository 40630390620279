import {
  Center, Divider, HStack, Image, Link,
} from '@chakra-ui/react';
import style from './style.module.scss';
import UnmazeLogo from '../../assets/img/unmaze/unmaze-logo.svg';

export default function HeaderTeacherPlatform() {
  return (
    <HStack className={style.header}>
      <Link href="/home/teacher">
        <Image src={UnmazeLogo} w="100px" />
      </Link>
    </HStack>
  );
}
