import {
  Button, Menu, MenuButton, MenuItem, MenuList, useDisclosure,
} from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import style from '../../style.module.scss';

export default function TabRegister() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const location = useLocation();

  return (
    <Menu
      isOpen={isOpen}
      h="100%"
    >
      <MenuButton
        as={Button}
        borderRadius="7px"
        _hover={{ backgroundColor: 'rgba(14, 17, 156, 0.17)' }}
        _focus={{ border: 'none' }}
        color="#fff"
        bgColor="#0E119C"
        onClick={onOpen}
        textAlign="center"
      >
        {(location.pathname === '/'
          || location.pathname === '/for-students'
          || location.pathname === '/login'
          || location.pathname === '/recovery')
          ? 'Cadastre-se' : 'Register'}

      </MenuButton>
      <MenuList
        onMouseEnter={onOpen}
        onMouseLeave={onClose}
        borderRadius={0}
      >
        <MenuItem
          w="100%"
          flexDirection="column"
          className={style['menu-nav-item']}
        >
          <NavLink to="/register-student/step-one" className={style['menu-item']}>
            {(location.pathname === '/'
              || location.pathname === '/for-students'
              || location.pathname === '/login'
              || location.pathname === '/recovery')
              ? 'Quero ter aulas' : 'Register as student'}
          </NavLink>

        </MenuItem>

        <MenuItem
          w="100%"
          flexDirection="column"
          className={style['menu-nav-item']}
        >
          <NavLink to="/register-teacher/step-one" className={style['menu-item']}>
            {(location.pathname === '/'
              || location.pathname === '/for-students'
              || location.pathname === '/login'
              || location.pathname === '/recovery')
              ? 'Quero dar aulas' : 'Register as teacher'}
          </NavLink>
        </MenuItem>
      </MenuList>
    </Menu>
  );
}
