import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import api from '../../services/api';
import notify from '../../utils/notify';
import CustomToastError from '../CustomToastError';
import style from './style.module.scss';
import { useStores } from '../../stores';

export default function ModalConfirmationSimple({ buttonName, userType }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    userStore: {
      token,
    },
  } = useStores();

  async function handleTermsRequestReview() {
    try {
      const response = await api.patch(`/terms/request-review?userType=${userType}`, { acceptedTerms: false }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status > 205) {
        throw new Error(response.data);
      }
      return notify(<CustomToastError
        toastText={response.data}
      />);
    } catch (error) {
      const { request } = error;

      if (request) {
        notify(<CustomToastError
          toastText={request.response.data}
        />);

        return;
      }
      return notify(<CustomToastError
        toastText={error.message}
      />);
    }
  }

  return (
    <>
      <Text
        className={style['menu-item']}
        onClick={onOpen}
        cursor="pointer"
        _hover={{ backgroundColor: 'rgba(14, 17, 156, 0.17)' }}
      >
        {buttonName}
      </Text>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Are you sure?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            This action will require the
            {' '}
            {userType}
            &apos;s to review and accept Terms of Use!
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="ghost"
              onClick={() => {
                handleTermsRequestReview();
                onClose();
              }}
            >
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
