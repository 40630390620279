import { useLocalStorage } from 'react-use';

export function useUser() {
  const [userData, setUserData, removerUserData] = useLocalStorage('userData');

  const token = localStorage.getItem('token') || null;

  function setToken(newToken) {
    localStorage.setItem('token', newToken);
  }

  function removeToken() {
    localStorage.removeItem('teacher-register');
    localStorage.removeItem('currentUserDetail');
    localStorage.removeItem('student-register');
    localStorage.removeItem('token');
    localStorage.removeItem('trackcode');
  }

  function handleClearUserData() {
    removeToken();
    removerUserData();
  }

  return {
    token,
    setToken,
    userData,
    setUserData,
    handleClearUserData,
  };
}
