import SuccessBell from '../../assets/img/notify/success.svg';
import style from './style.module.scss';

export default function CustomToastSuccess({ toastText }) {
  return (
    <div className={style.toast}>
      <img src={SuccessBell} alt="success info" />
      <div className={style.toastText}>
        <p>{toastText}</p>
      </div>
    </div>
  );
}
