import { HamburgerIcon } from '@chakra-ui/icons';
import {
  Button, Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay, HStack, Link, Menu,
  MenuDivider,
  MenuGroup,
  MenuItem,
  Popover,
  PopoverArrow, PopoverCloseButton,
  PopoverContent,
  PopoverFooter, PopoverTrigger, useDisclosure,
} from '@chakra-ui/react';
import { useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import style from '../style.module.scss';

export default function DrawerSite() {
  const {
    isOpen, onOpen, onClose,
  } = useDisclosure();
  const btnRef = useRef();

  const location = useLocation();

  const history = useHistory();

  function redirectTo(value) {
    history.push(value);
    onClose();
  }

  return (
    <>
      <Button ref={btnRef} bgColor="#0e119c" color="#fff" onClick={onOpen}>
        <HamburgerIcon />
      </Button>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
        preserveScrollBarGap
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader textAlign="center">
            {(location.pathname === '/'
              || location.pathname === '/for-students'
              || location.pathname === '/login')
              ? 'Bem-vindo a Unmaze!' : 'Welcome to Unmaze!'}

          </DrawerHeader>

          <DrawerBody px={0} onClick={onClose}>
            <Menu>

              <MenuGroup
                title={(location.pathname === '/'
                  || location.pathname === '/for-students'
                  || location.pathname === '/login')
                  ? 'Navegar' : 'Navigate'}
                color="rgba(14, 17, 156, 1)"
                textAlign="start"
              >
                <MenuItem
                  w="100%"
                  _hover={{ backgroundColor: 'rgba(14, 17, 156, 0.17)' }}
                >
                  <NavLink
                    to="/"
                    exact
                    className={style['menu-item-drawer']}
                  >
                    {(location.pathname === '/'
                      || location.pathname === '/for-students'
                      || location.pathname === '/login')
                      ? 'Início' : 'Home'}
                  </NavLink>

                </MenuItem>

                <MenuItem
                  w="100%"
                  _hover={{ backgroundColor: 'rgba(14, 17, 156, 0.17)' }}
                >
                  <NavLink
                    to="/for-students"
                    exact
                    className={style['menu-item-drawer']}
                  >
                    {(location.pathname === '/'
                      || location.pathname === '/for-students'
                      || location.pathname === '/login')
                      ? 'Para alunos' : 'For students'}
                  </NavLink>
                </MenuItem>

                <MenuItem
                  w="100%"
                  _hover={{ backgroundColor: 'rgba(14, 17, 156, 0.17)' }}
                >
                  <NavLink
                    to="/for-teachers"
                    exact
                    className={style['menu-item-drawer']}
                  >
                    {(location.pathname === '/'
                      || location.pathname === '/for-students'
                      || location.pathname === '/login')
                      ? 'Para professores' : 'For teachers'}
                  </NavLink>

                </MenuItem>
              </MenuGroup>

              <MenuDivider borderColor="rgba(14, 17, 156, 0.17)" />

              <MenuGroup
                title="Unmaze"
                color="rgba(14, 17, 156, 1)"
                textAlign="start"
              >

                <MenuItem
                  w="100%"
                  _hover={{ backgroundColor: 'rgba(14, 17, 156, 0.17)' }}
                >
                  <Link
                    isExternal
                    href="https://api.whatsapp.com/send?phone=5511936185850&text=Ol%C3%A1,%20vim%20pelo%20site%20e%20gostaria%20de%20ter%20mais%20informa%C3%A7%C3%B5es"
                    className={style['menu-item-drawer']}
                  >
                    {(location.pathname === '/'
                      || location.pathname === '/for-students'
                      || location.pathname === '/login')
                      ? 'Entre em contato' : 'Contact us'}

                  </Link>

                </MenuItem>
              </MenuGroup>
            </Menu>
          </DrawerBody>

          <DrawerFooter bgColor="#0e119c">
            <Button
              variant="outline"
              color="#fff"
              borderColor="#fff"
              _hover={{ backgroundColor: '#80aff1' }}
              mr={3}
              onClick={() => redirectTo('/')}
            >
              {(location.pathname === '/'
                || location.pathname === '/for-students'
                || location.pathname === '/login')
                ? 'Entrar' : 'Log in'}
            </Button>

            <Popover
              placement="top-end"
              className={style.pobody}
            >
              <PopoverTrigger>
                <Button
                  color="#0e119c"
                  bgColor="#fff"
                  _hover={{ backgroundColor: '#80aff1' }}
                >
                  {(location.pathname === '/'
                    || location.pathname === '/for-students'
                    || location.pathname === '/login')
                    ? 'Criar conta' : 'Create account'}

                </Button>
              </PopoverTrigger>
              <PopoverContent
                gap={0}
                w="100%"
                px={5}
                className={style.pocontent}
              >
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverFooter py={5} textAlign="center">
                  {(location.pathname === '/'
                    || location.pathname === '/for-students'
                    || location.pathname === '/login')
                    ? 'Escolha o tipo de conta' : 'Choose account type'}
                  <HStack
                    justifyContent="flex-start"
                    py={3}
                  >
                    <Button
                      color="#fff"
                      bgColor="#0e119c"
                      _hover={{ backgroundColor: 'rgba(14, 17, 156, 0.17)' }}
                      onClick={() => redirectTo('/register-student/step-one')}
                    >
                      {(location.pathname === '/'
                        || location.pathname === '/for-students'
                        || location.pathname === '/login')
                        ? 'Aluno' : 'Student'}

                    </Button>
                    <Button
                      color="#fff"
                      bgColor="#0e119c"
                      _hover={{ backgroundColor: 'rgba(14, 17, 156, 0.17)' }}
                      onClick={() => redirectTo('/register-teacher/step-one')}
                    >
                      {(location.pathname === '/'
                        || location.pathname === '/for-students'
                        || location.pathname === '/login')
                        ? 'Professor' : 'Teacher'}

                    </Button>
                  </HStack>

                </PopoverFooter>
              </PopoverContent>
            </Popover>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}
