import { createContext, useContext } from 'react';

import { useRegisterUser } from './registerStore';

const storesCtx = createContext(null);

export function useRegisterStores() {
  return useContext(storesCtx);
}

export function RegisterStoresProvider({ children }) {
  const registerUserStore = useRegisterUser();

  return (
    <storesCtx.Provider value={{ registerUserStore }}>
      {children}
    </storesCtx.Provider>
  );
}
