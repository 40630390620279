import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  Button,
  Center,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator,
  HStack, Heading, Icon, Image, Stack, StackDivider, Tag, Text, VStack, Link,
} from '@chakra-ui/react';
import {
  AddIcon, CheckCircleIcon, LockIcon, UnlockIcon,
} from '@chakra-ui/icons';
import { AiOutlineFilePdf } from 'react-icons/ai';
import Media from 'react-media';
import { FaRegFileVideo } from 'react-icons/fa';
import { BsDot } from 'react-icons/bs';
import style from './style.module.scss';

export function FreeLessons() {
  const courses = [
    {
      id: 0,
      description: 'Aulas de inglês',
      type: 'free',
      progression: 0,
      classes: [
        {
          id: 1,
          link: 'https://deepenglish.com/lessons/dont-be-a-lemming-listening-fluency-117/',
          image: 'https://static.wixstatic.com/media/6935cc_ae97c8136a4e400487503328b122132a~mv2.jpg/v1/fill/w_1200,h_754,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Group%20483552_Easy-Resize_com.jpg',
          available: true,
          color: 'blue.500',
          title: 'Don’t Be A Lemming',
          text: 'Humans are herd animals',
        },
        {
          id: 2,
          link: 'https://deepenglish.com/lessons/names-affect-childrens-future/',
          image: 'https://static.wixstatic.com/media/6935cc_ae97c8136a4e400487503328b122132a~mv2.jpg/v1/fill/w_1200,h_754,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Group%20483552_Easy-Resize_com.jpg',
          available: true,
          color: 'blue.500',
          title: 'Does your name determine your future?',
          text: 'Find out',
        },
        {
          id: 3,
          link: 'https://deepenglish.com/lessons/meet-creatures-strangest-jobs/',
          image: 'https://static.wixstatic.com/media/6935cc_ae97c8136a4e400487503328b122132a~mv2.jpg/v1/fill/w_1200,h_754,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Group%20483552_Easy-Resize_com.jpg',
          available: true,
          color: 'blue.500',
          title: 'Meet The Creatures With The Strangest Jobs',
          text: 'Creatures of all shapes and sizes have played a pivotal role in humanity’s history.',
        },
        {
          id: 4,
          link: 'https://deepenglish.com/lessons/language-numbers-learn-english/',
          image: 'https://static.wixstatic.com/media/6935cc_ae97c8136a4e400487503328b122132a~mv2.jpg/v1/fill/w_1200,h_754,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Group%20483552_Easy-Resize_com.jpg',
          available: true,
          color: 'blue.500',
          title: 'Man Uses The Language Of Numbers To Learn English',
          text: 'Daniel Tammet was born in London. His mother spoke English, and he grew up in an English-speaking environment. Yet, to his ears, the English language was an alien language.',
        },
        {
          id: 5,
          link: 'https://deepenglish.com/lessons/archeologist-dug-up-curse/',
          image: 'https://static.wixstatic.com/media/6935cc_ae97c8136a4e400487503328b122132a~mv2.jpg/v1/fill/w_1200,h_754,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Group%20483552_Easy-Resize_com.jpg',
          available: true,
          color: 'blue.500',
          title: 'The Hidden Curse Of An Ancient Boy King',
          text: 'In 1922, archeologist Howard Carter found the tomb of King Tutankhamun, the boy king, also known as King Tut.',
        },
      ],
    },
  ];

  return (
    <div className={style.main}>
      <div className={style.container}>
        <HStack pb="2px" pt="50px" alignSelf="left" fontweight="400px" fontSize={14}>
          <Breadcrumb separator=">">
            <BreadcrumbItem color="#0e119c">
              <BreadcrumbLink href="/platform/content-area">Área de conteúdo</BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem>
              <BreadcrumbLink href="/platform/free-lessons">Página atual</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        </HStack>
        <HStack>
          {/* <Link href="https://platform.unmaze.com.br/platform/content-area">
            <Heading pb="2px" pt="50px" alignSelf="left"
            color="#06097D" fontweight="300px" fontSize={15}>Área gratuita</Heading>
          </Link>
          <Heading pb="2px" pt="50px" alignSelf="left"
          color="#06097D" fontweight="400px" fontSize={15}>&gt;</Heading> */}
          <Heading pb="1px" pt="5px" alignSelf="left" color="#06097D" fontweight="300px" fontSize={18}>Free Lessons</Heading>
        </HStack>
        <VStack w="100%" alignItems="flex-start" spacing={2}>
          {courses.map((course) => (
            <VStack spacing={1} w="100%" alignItems="flex-start">
              <Heading alignSelf="left" size="2xl">{course.title}</Heading>
              <VStack w="100%" spacing={0} divider={<StackDivider borderColor="gray.200" />}>
                {course.classes.map((currentClass) => (
                  <HStack w="100%" bgColor="#fff">
                    <Media queries={{
                      mobile: '(max-width: 600px)',
                      desktop: '(min-width: 600px)',
                    }}
                    >
                      {(matches) => (
                        <>
                          {matches.mobile && (
                          <>
                            <VStack
                              as="a"
                              margin="2"
                              divider={<StackDivider borderColor="gray.200" />}
                              h="100%"
                              bgColor="#fff"
                              href={!currentClass.available ? undefined : currentClass.link}
                              className={style[`${!currentClass.available && 'disabled'}`]}
                              target="_blank"
                            >
                              <Stack direction={['column', 'row']} w="100%" justifyContent="space-between" pr={0}>
                                <Box minH={[35, 35, 35]} margin="auto 0" lineHeight={[100, 200, 250]} pr={0} alignItems="flex-start" verticalAlign="flex-start" pl={0}>
                                  <Center>
                                    <Image
                                      maxH={600}
                                      maxW={100}
                                      src={currentClass.image}
                                    />
                                  </Center>
                                </Box>
                              </Stack>
                            </VStack>
                            <VStack pl={1} alignItems="flex-start">
                              <Link href={currentClass.link}>
                                <Text alignItems="left" fontWeight="600" color="#06097D" fontSize="12px" marginBottom="0" marginTop="4" pb="0" marginRight="2" marginLeft="0">
                                  {currentClass.title}
                                </Text>
                              </Link>
                              <Text fontSize="8px" color="#000" marginBottom="4" marginTop="0" pt="0" marginRight="2" marginLeft="0">
                                {currentClass.text}
                              </Text>
                            </VStack>
                          </>
                          )}
                          {matches.desktop && (
                          <>
                            <VStack
                              as="a"
                              margin="2"
                              divider={<StackDivider borderColor="gray.200" />}
                              h="100%"
                              bgColor="#fff"
                              href={!currentClass.available ? undefined : currentClass.link}
                              className={style[`${!currentClass.available && 'disabled'}`]}
                              target="_blank"
                            >
                              <Stack direction={['column', 'row']} w="100%" justifyContent="space-between">
                                <Box minH={[150, 150, 150]} margin="auto 0" lineHeight={[100, 200, 250]} alignItems="left" verticalAlign="center" p={1}>
                                  <Center>
                                    <Image
                                      maxW={200}
                                      maxH={200}
                                      src={currentClass.image}
                                      marginTop="4"
                                      ml="3"
                                    />
                                  </Center>
                                </Box>
                              </Stack>
                            </VStack>
                            <VStack pl={10} alignItems="flex-start">
                              <Link href={currentClass.link}>
                                <Text fontWeight="600" color="#06097D" fontSize="16px" marginTop="4" marginRight="4" marginLeft="0">
                                  {currentClass.title}
                                </Text>
                              </Link>
                              <Text fontSize="14px" color="#000" marginBottom="4" marginRight="4" marginLeft="0">
                                {currentClass.text}
                              </Text>
                            </VStack>
                          </>
                          )}
                        </>
                      )}
                    </Media>
                  </HStack>
                ))}
              </VStack>
            </VStack>
          ))}
        </VStack>
      </div>
    </div>
  );
}
