import { useState } from 'react';
import { validatePassword } from '../utils/validatePassword';

export function usePasswordValidation() {
  const [containsUL, setContainsUL] = useState(false);
  const [containsLL, setContainsLL] = useState(false);
  const [containsN, setContainsN] = useState(false);
  const [containsSC, setContainsSC] = useState(false);
  const [contains8C, setContains8C] = useState(false);
  const [passwordValue, setPasswordValue] = useState('');

  function handlePasswordValidation(password) {
    const passwordValidation = validatePassword(password);

    setContainsUL(passwordValidation.containsUL);
    setContainsLL(passwordValidation.containsLL);
    setContainsN(passwordValidation.containsN);
    setContainsSC(passwordValidation.containsSC);
    setContains8C(passwordValidation.contains8C);
  }

  function resetPasswordValidation() {
    setPasswordValue('');
  }

  return {
    containsUL,
    containsLL,
    containsN,
    containsSC,
    contains8C,
    passwordValue,
    setPasswordValue,
    handlePasswordValidation,
    resetPasswordValidation,
  };
}
