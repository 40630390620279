import {
  Button, Menu, MenuButton, MenuItem, MenuList, useDisclosure,
} from '@chakra-ui/react';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import style from '../../style.module.scss';

export default function TabForYou() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const location = useLocation();
  const history = useHistory();

  return (
    <Menu
      isOpen={isOpen}
      h="100%"
      w="100%"
    >
      <MenuButton
        as={Button}
        variant="none"
        borderRadius={0}
        _hover={{ textDecoration: 'underline', boxShadow: 'none' }}
        _focus={{ boxShadow: 'none' }}
        color="#0E119C"
        onClick={isOpen ? onClose : onOpen}
        h="100%"
        p={0}
        className={location.pathname.includes('/for-') ? style['nav-active'] : style.nav}
      >
        {(location.pathname === '/'
          || location.pathname === '/for-students'
          || location.pathname === '/login')
          ? 'Para você' : 'For you'}

      </MenuButton>
      <MenuList
        onMouseEnter={onOpen}
        onMouseLeave={onClose}
        marginTop="-6px"
        borderRadius={0}
      >
        <MenuItem
          w="100%"
          h="100%"
          flexDirection="column"
          textAlign="start"
          onClick={() => history.push('/for-teachers')}
          className={style['menu-nav-item']}
        >
          {(location.pathname === '/'
            || location.pathname === '/for-students'
            || location.pathname === '/login')
            ? 'Para professores' : 'For teachers'}
        </MenuItem>

        {/* //* para alunos */}
        <MenuItem
          w="100%"
          h="100%"
          flexDirection="column"
          onClick={() => history.push('/for-students')}
          className={style['menu-nav-item']}
        >
          {(location.pathname === '/'
            || location.pathname === '/for-students'
            || location.pathname === '/login')
            ? 'Para alunos' : 'For students'}
        </MenuItem>

        {/* //* para empresas */}
        {/* <MenuItem
          w="100%"
          h="100%"
          flexDirection="column"
          onClick={() => history.push('/for-companies')}
          className={style['menu-nav-item']}
        >
          {location.pathname === '/' ? 'Para empresas' : 'For companies'}
        </MenuItem> */}
      </MenuList>
    </Menu>
  );
}
