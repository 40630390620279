import { useLocalStorage } from 'react-use';

export function useRegisterUser() {
  const [
    registerUserType,
    setRegisterUserType,
    removeRegisterUserType,
  ] = useLocalStorage('rg_usertype');

  const [
    registerTeacher,
    setRegisterTeacher,
    removeRegisterTeacher,
  ] = useLocalStorage('rg_teacher');

  const [
    registerStudent,
    setRegisterStudent,
    removeRegisterStudent,
  ] = useLocalStorage('rg_student');

  return {
    registerUserType,
    setRegisterUserType,
    removeRegisterUserType,
    registerTeacher,
    setRegisterTeacher,
    removeRegisterTeacher,
    registerStudent,
    setRegisterStudent,
    removeRegisterStudent,
  };
}
