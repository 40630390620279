import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Avatar,
  Button, HStack, Image, Link, Menu, MenuButton, MenuDivider, MenuGroup, MenuItem,
  MenuList, Portal, Text,
} from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useStores } from '../../../stores';
import style from '../style.module.scss';
import ModalConfirmationSimple from '../../ModalConfirmationSimple';
import ConfigIcon from '../../../assets/img/platform/navigation/config-icon.svg';

function DropdownNavAdmin({ drawer }) {
  const {
    userStore: {
      userData,
      handleClearUserData,
      token,
    },
  } = useStores();
  const { avatar } = userData;
  const history = useHistory();
  const location = useLocation();

  function handleLogout() {
    handleClearUserData();
    history.push('/');
  }

  return (
    <Menu w="100%" zIndex={3222}>
      <MenuButton
        as={Button}
        variant="none"
        p={0}
        h={!drawer ? '32px' : '36px'}
        width={!drawer ? '100%' : '36px'}
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        <HStack
          marginLeft={(drawer || location.pathname === `/profile/${userData.userType.toLowerCase()}`) ? 0 : '15px'}
          w="100%"
          spacing={2}
          h={!drawer ? '32px' : '36px'}
          fontSize="sm"
        >
          {!drawer && location.pathname === `/profile/${userData.userType.toLowerCase()}`
            && <div className={style.active__marker} />}

          <Image src={ConfigIcon} boxSize={!drawer ? '24px' : '36px'} />
          {!drawer && <Text fontWeight={600}>{userData.userType === 'Student' ? 'Configurações' : 'Settings'}</Text>}
        </HStack>
      </MenuButton>
      <Portal>
        <MenuList borderColor="rgba(14, 17, 156, 0.17)" zIndex={333}>
          <MenuGroup title="User guidelines" textAlign="start">
            <MenuItem
              w="100%"
              _hover={{ backgroundColor: 'rgba(14, 17, 156, 0.17)' }}
            >
              <NavLink
                className={style['menu-item']}
                to="/home/admin/student-guidelines"
              >
                Student

              </NavLink>
            </MenuItem>
            <MenuItem
              w="100%"
              _hover={{ backgroundColor: 'rgba(14, 17, 156, 0.17)' }}
            >
              <NavLink
                className={style['menu-item']}
                to="/home/admin/teacher-guidelines"
              >
                Teacher

              </NavLink>
            </MenuItem>
          </MenuGroup>

          <MenuDivider borderColor="rgba(14, 17, 156, 0.17)" />

          <MenuGroup title="Tools" textAlign="start">
            <MenuItem
              w="100%"
              _hover={{ backgroundColor: 'rgba(14, 17, 156, 0.17)' }}
            >
              <Link
                className={style['menu-item']}
                isExternal
                rel="noreferrer noopener"
                href="https://midnight-archduke-8c8.notion.site/Troubleshooting-9dac43d9f6f04e639ee5ef33f82d600a?pvs=4"
              >
                Troubleshooting

              </Link>
            </MenuItem>

            <MenuItem
              w="100%"
              _hover={{ backgroundColor: 'rgba(14, 17, 156, 0.17)' }}
            >
              <Link
                className={style['menu-item']}
                isExternal
                rel="noreferrer noopener"
                href="https://midnight-archduke-8c8.notion.site/Rating-571ca8867a6742d8a7fba448cc5e2530?pvs=4"
              >
                Teacher Rating

              </Link>
            </MenuItem>

            <MenuItem
              w="100%"
              _hover={{ backgroundColor: 'rgba(14, 17, 156, 0.17)' }}
            >
              <NavLink
                className={style['menu-item']}
                to="/home/admin/modal-controls"
              >
                Modal Controls

              </NavLink>
            </MenuItem>

            <MenuItem
              w="100%"
              _hover={{ backgroundColor: 'rgba(14, 17, 156, 0.17)' }}
            >
              <NavLink
                className={style['menu-item']}
                to="/home/admin/moderate-comments"
              >
                Moderate Comments

              </NavLink>
            </MenuItem>

          </MenuGroup>

          <MenuDivider borderColor="rgba(14, 17, 156, 0.17)" />

          <MenuGroup title="Terms of Use" textAlign="start">
            <ModalConfirmationSimple
              buttonName={'Request Students\' review'}
              userType="Student"
            />
            <ModalConfirmationSimple
              buttonName={'Request Teachers\' review'}
              userType="Teacher"
            />
          </MenuGroup>

        </MenuList>
      </Portal>
    </Menu>
  );
}

export default DropdownNavAdmin;
