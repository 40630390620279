/* eslint-disable prefer-spread */
import { Image } from '@chakra-ui/react';
import Star from '../../assets/img/star.png';
import HalfStar from '../../assets/img/half-star.png';
import EmptyStar from '../../assets/img/empty-star.png';
import styles from './styles.module.scss';

function RatingCount({ rate }) {
  function calculateRatingStar() {
    const countStar = 5 - rate;

    if (countStar > 0) {
      return countStar;
    }
  }

  return (
    <div className={styles.container}>
      {Array.apply(null, { length: rate }).map((e, i) => (
        <Image src={Star} />
      ))}

      {calculateRatingStar() && <Image src={calculateRatingStar() === 1 ? EmptyStar : HalfStar} /> }

    </div>
  );
}

export default RatingCount;
