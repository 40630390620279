/* eslint-disable import/no-unresolved */
import React from 'react';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { useStores } from '../stores';
import { Routes } from './routes';

function App() {
  const { loadingStore: { openLoading } } = useStores();

  return (
    <React.Suspense fallback={null}>
      <Routes />
    </React.Suspense>
  );
}

export default App;
