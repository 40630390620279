import { useLocalStorage } from 'react-use';

export function useUseCredits() {
  const [useCredits, setUseCredits, removeUseCredits] = useLocalStorage('clicked-use-credits');

  function handleClearUseCredits() {
    removeUseCredits();
  }

  return {
    useCredits,
    setUseCredits,
    handleClearUseCredits,
  };
}
