import { CheckIcon, EmailIcon } from '@chakra-ui/icons';
import {
  Center, Text, Tooltip, VStack,
} from '@chakra-ui/react';
import api from '../../services/api';
import { useStores } from '../../stores';
import notify from '../../utils/notify';
import CustomToastError from '../CustomToastError';
import style from './style.module.scss';
// import socket from '../../services/socket';

export default function NotificationCard({
  description,
  creationDistance,
  isRead,
  id,
  type,
  index,
}) {
  const {
    notificationStore: {
      unreadNotifications,
      setUnreadNotifications,
      setNotifications,
      notifications,
    },
    userStore: {
      userData, setUserData, token,
    },
  } = useStores();

  async function changeNotificationReadStatus() {
    const response = await api.patch(`/notification/${id}`, {}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status > 205) {
      notify(<CustomToastError
        toastText={response.data}
      />);
      return;
    }

    notifications[index].isRead = !isRead;

    if (notifications[index].isRead === true) {
      if (unreadNotifications <= 1) {
        setUnreadNotifications(0);
      } else {
        setUnreadNotifications(unreadNotifications - 1);
      }
    } else { setUnreadNotifications(unreadNotifications + 1); }

    localStorage.setItem('notifications-count', unreadNotifications);
    setNotifications(notifications);
    setUserData({ ...userData, notifications });
  }

  async function readChanger() {
    await changeNotificationReadStatus();
  }

  // function removeItem() {

  // }

  function returnStyle() {
    if (isRead === false) {
      return 'default';
    }
    return 'read';
  }

  return (
    <div
      className={`${style.notification} ${style[returnStyle()]}`}
    >
      <Text alignSelf="start" fontSize="sm" maxW="90%">{description}</Text>
      <VStack>
        <Text
          fontWeight={500}
          fontSize="12px"
          align="end"
          w="100%"
          color="#7d7da3"
          className={style['mark-not-read-btn']}
        >
          {creationDistance}
          {' '}
          {userData.userType === 'Student' ? 'atrás' : ''}
        </Text>
        <Tooltip
          bg="#060f9b"
          hasArrow
          placement="top-start"
          p={4}
          borderRadius={4}
          label={
            (userData.userType === 'Student') ? `Marcar como ${!notifications[index].isRead === true ? 'lida' : 'não lida'}` : `Mark as ${!notifications[index].isRead === true ? 'read' : 'unread'}`
          }
          fontSize="md"
        >
          <button
            className={style['close-btn']}
            onClick={() => readChanger(id)}
          >
            <Center>
              {notifications[index].isRead === true ? <CheckIcon /> : <EmailIcon />}
            </Center>
          </button>
        </Tooltip>
      </VStack>
    </div>
  );
}
