import {
  Center,
  HStack,
  Image,
  Text, VStack,
} from '@chakra-ui/react';
import {
  HiOutlineTicket,
} from 'react-icons/hi';
import {
  MdOutlineDomain, MdOutlinePermMedia,
} from 'react-icons/md';
import {
  NavLink, useHistory, useLocation,
} from 'react-router-dom';
import { CiDiscount1 } from 'react-icons/ci';
import HomeIcon from '../../../assets/img/platform/navigation/home-icon.svg';
import LogoutIcon from '../../../assets/img/platform/navigation/logout.svg';
import { useStores } from '../../../stores';
import NavbarDrawerAdmin from '../../NavbarDrawerAdmin';
import NotificationBell from '../../NotificationBell';
import UserCounter from '../../UserCounter';
import DropdownNavAdmin from '../../UserNavigation/DropdownNavAdmin';
import style from '../style.module.scss';
import UnmazeIcon from '../../../assets/img/platform/logo/unmaze-white-txt.svg';

export default function NavbarAdmin({ children }) {
  const {
    userStore:
    { handleClearUserData, userData },
  } = useStores();

  const location = useLocation();

  const history = useHistory();

  function handleLogout() {
    handleClearUserData();
    history.push('/');
  }

  return (
    <div className={style.main__body}>
      <div className={style.responsive__navbar}>
        <NavbarDrawerAdmin />
      </div>
      <div className={style.navbar__container}>
        <VStack className={style.nav__holder}>
          <Center>
            <Image
              src={UnmazeIcon}
              w="100px"
            />
          </Center>
          <VStack className={style.navs} alignItems="flex-start">
            <VStack w="100%" alignItems="flex-start">
              <NavLink
                to="/home/admin/users"
              >
                <HStack
                  marginLeft={(location.pathname === '/home/admin/users') ? 0 : '15px'}
                  w="100%"
                  spacing={2}
                  h="32px"
                >
                  {location.pathname === '/home/admin/users'
                    && <div className={style.active__marker} />}

                  <Image src={HomeIcon} boxSize="24px" />
                  <Text>Users</Text>
                </HStack>
              </NavLink>

              <NavLink
                to="/home/admin"
              >
                <HStack
                  marginLeft={location.pathname === '/home/admin' ? 0 : '15px'}
                  w="100%"
                  spacing={2}
                  h="32px"
                >
                  {location.pathname === '/home/admin'
                    && <div className={style.active__marker} />}

                  <Image src={HomeIcon} boxSize="24px" />
                  <Text>Prospects</Text>
                </HStack>
              </NavLink>

              <NavLink
                to="/home/admin/companies"
              >
                <HStack
                  marginLeft={location.pathname === '/home/admin/companies' ? 0 : '15px'}
                  w="100%"
                  spacing={2}
                  h="32px"
                >
                  {(location.pathname === '/home/admin/companies')
                    && <div className={style.active__marker} />}

                  <MdOutlineDomain fontSize="24px" />
                  <Text>Companies</Text>
                </HStack>
              </NavLink>

              <NavLink
                to="/home/admin/coupons"
              >
                <HStack
                  marginLeft={location.pathname === '/home/admin/coupons' ? 0 : '15px'}
                  w="100%"
                  spacing={2}
                  h="32px"
                >
                  {location.pathname === '/home/admin/coupons'
                    && <div className={style.active__marker} />}

                  <HiOutlineTicket fontSize="24px" />
                  <Text>Coupons</Text>
                </HStack>
              </NavLink>

              <NavLink
                to="/home/admin/discount-links"
              >
                <HStack
                  marginLeft={location.pathname === '/home/admin/discount-links' ? 0 : '15px'}
                  w="100%"
                  spacing={2}
                  h="32px"
                >
                  {location.pathname === '/home/admin/discount-links'
                    && <div className={style.active__marker} />}

                  <CiDiscount1 fontSize="24px" />
                  <Text>Track Links</Text>
                </HStack>
              </NavLink>
              <NavLink
                to="/home/admin/user-expiration-today"
              >
                <HStack
                  marginLeft={location.pathname === '/home/admin/user-expiration-today' ? 0 : '15px'}
                  w="100%"
                  spacing={2}
                  h="32px"
                >
                  {location.pathname === '/home/admin/user-expiration-today'
                    && <div className={style.active__marker} />}

                  <CiDiscount1 fontSize="24px" />
                  <Text>Users Credits Expired Today</Text>
                </HStack>
              </NavLink>

              <NavLink
                to="/home/admin/backups/teacher"
              >
                <HStack
                  marginLeft={(location.pathname === '/home/admin/backups/teacher'
                    || location.pathname === '/home/admin/backups/student') ? 0 : '15px'}
                  w="100%"
                  spacing={2}
                  h="32px"
                >
                  {(location.pathname === '/home/admin/backups/teacher'
                    || location.pathname === '/home/admin/backups/student')
                    && <div className={style.active__marker} />}

                  <MdOutlinePermMedia fontSize="24px" />
                  <Text>Backup</Text>
                </HStack>
              </NavLink>

              <VStack className={style.backups} alignItems="flex-start">
                <NavLink
                  to="/home/admin/backups/teacher"
                >
                  <HStack
                    marginLeft="15px"
                    w="100%"
                    spacing={2}
                    h="32px"
                    textDecoration={location.pathname === '/home/admin/backups/teacher' && 'underline'}
                  >
                    <Text>Teachers&apos; request</Text>
                  </HStack>
                </NavLink>

                <NavLink
                  to="/home/admin/backups/student"
                >
                  <HStack
                    marginLeft="15px"
                    w="100%"
                    spacing={2}
                    h="32px"
                    textDecoration={location.pathname === '/home/admin/backups/student' && 'underline'}
                  >

                    <Text>Students&apos; request</Text>
                  </HStack>
                </NavLink>
              </VStack>
            </VStack>

          </VStack>
        </VStack>

        <UserCounter />

        <VStack className={style.navs}>
          <VStack w="100%" alignItems="flex-start">
            <HStack w="100%">
              <NotificationBell />
            </HStack>
            <DropdownNavAdmin />
            <HStack
              as="button"
              w="100%"
              h="32px"
              onClick={() => handleLogout()}
            >
              <Image src={LogoutIcon} boxSize="24px" ml="15px" />
              <Text fontSize="14px" fontWeight={600} w="100%" textAlign="start">
                Logout

              </Text>
            </HStack>
          </VStack>

        </VStack>
      </div>

      <div className={style.main__content}>
        {children}
      </div>

    </div>
  );
}
