import { useLocalStorage } from 'react-use';

export function useNavStore() {
  const [currentNav, setCurrentNav, removeCurrentNav] = useLocalStorage('navLink');

  return {
    currentNav,
    setCurrentNav,
    removeCurrentNav,
  };
}
