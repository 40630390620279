import { useEffect, useState } from 'react';
import { Divider, Text } from '@chakra-ui/react';
import api from '../../services/api';
import { useStores } from '../../stores';
import notify from '../../utils/notify';
import CustomToastError from '../CustomToastError';
import style from './style.module.scss';

export default function UserCounter() {
  const { userStore: { token } } = useStores();
  const [userCounterData, setUserCounterData] = useState();

  useEffect(() => {
    async function loadUserData() {
      try {
        const { data } = await api.get('/userstotal', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setUserCounterData(data);
      } catch (error) {
        const { request } = error;

        if (request) {
          notify(<CustomToastError
            toastText={request.response}
          />);
          return;
        }

        return notify(<CustomToastError
          toastText={error.message}
        />);
      }
    }
    loadUserData();
  }, []);

  return (
    <div className={style['counter-container']}>
      <div className={style['user-counter']}>
        <Text fontWeight={600}>ACTIVE USERS</Text>

        <Text>
          Students:
          {' '}
          {userCounterData?.activeStudents || 0}

        </Text>
        <Text>
          Teachers:
          {' '}
          {userCounterData?.activeTeachers || 0}
        </Text>

        <Text fontWeight={600}>PAUSED USERS</Text>
        <Text>
          Students:
          {' '}
          {userCounterData?.pausedStudents || 0}

        </Text>
      </div>
    </div>
  );
}
