import { HamburgerIcon } from '@chakra-ui/icons';
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter, DrawerHeader, DrawerOverlay,
  HStack,
  Image, Link,
  Text,
  useDisclosure, VStack,
} from '@chakra-ui/react';
import React from 'react';

import { MdOutlineDomain, MdOutlinePermMedia } from 'react-icons/md';
import { useHistory, useLocation } from 'react-router-dom';
import { HiOutlineTicket } from 'react-icons/hi';
import HomeIconBlue from '../../assets/img/platform/navigation/home-icon-blue.svg';
import LogoutIcon from '../../assets/img/platform/navigation/logout-blue.svg';
import NotificationsIconBlue from '../../assets/img/platform/navigation/notifications-icon-blue.svg';
import { useStores } from '../../stores';
import NotificationBell from '../NotificationBell';
import DropdownNavAdmin from '../UserNavigation/DropdownNavAdmin';
import style from './style.module.scss';

export default function NavbarDrawerAdmin() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  const location = useLocation();
  const {
    globalStatesStore: {
      setOpenSiteHomeModal,
    },
    userStore: {
      userData,
      handleClearUserData,
    },
  } = useStores();

  const history = useHistory();

  function handleLogout() {
    handleClearUserData();
    history.push('/');
  }

  return (
    <>
      <HStack alignItems="center" w="100%" justifyContent="space-between">
        <Button ref={btnRef} onClick={onOpen} variant="unstyled" color="#fff" h="60px">
          <HamburgerIcon boxSize="60px 20px" />
        </Button>
        <HStack>
          <DropdownNavAdmin drawer />
          <NotificationBell drawer />
        </HStack>
      </HStack>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            Welcome to Unmaze
          </DrawerHeader>

          <DrawerBody className={style['drawer-body']}>
            <VStack alignItems="start">
              <Link
                href="/home/admin/users"
                color="#0E119C"
                fontSize="lg"
              >
                <HStack
                  w="100%"
                  spacing={2}
                  h="32px"
                >

                  <Image src={HomeIconBlue} boxSize="24px" />
                  <Text>Users</Text>
                </HStack>
              </Link>

              <Link
                href="/home/admin"
                color="#0E119C"
                fontSize="lg"
              >
                <HStack
                  w="100%"
                  spacing={2}
                  h="32px"
                >

                  <Image src={HomeIconBlue} boxSize="24px" />
                  <Text>Prospects</Text>
                </HStack>
              </Link>

              <Link
                href="/home/admin/companies"
                color="#0E119C"
                fontSize="lg"
              >
                <HStack
                  w="100%"
                  spacing={2}
                  h="32px"
                >

                  <MdOutlineDomain fontSize="24px" />
                  <Text>Companies</Text>
                </HStack>
              </Link>

              <Link
                href="/home/admin/coupons"
                color="#0E119C"
                fontSize="lg"
              >
                <HStack
                  w="100%"
                  spacing={2}
                  h="32px"
                >

                  <HiOutlineTicket fontSize="24px" />
                  <Text>Coupons</Text>
                </HStack>
              </Link>

              <Link
                href="/home/admin/discount-links"
                color="#0E119C"
                fontSize="lg"
              >
                <HStack
                  w="100%"
                  spacing={2}
                  h="32px"
                >

                  <HiOutlineTicket fontSize="24px" />
                  <Text>Discount Links</Text>
                </HStack>
              </Link>
              <Link
                href="/home/admin/user-expiration-today"
                color="#0E119C"
                fontSize="lg"
              >
                <HStack
                  w="100%"
                  spacing={2}
                  h="32px"
                >

                  <HiOutlineTicket fontSize="24px" />
                  <Text>Users Credits Expired Today</Text>
                </HStack>
              </Link>

              <VStack className={style.backups}>
                <Link
                  href="/home/admin/backups/teacher"
                  color="#0E119C"
                  fontSize="lg"
                >
                  <HStack
                    w="100%"
                    spacing={2}
                    h="32px"
                  >

                    <MdOutlinePermMedia fontSize="24px" />
                    <Text>Backups</Text>
                  </HStack>
                </Link>
                <Link
                  href="/home/admin/backups/teacher"
                >
                  <HStack
                    marginLeft="15px"
                    w="100%"
                    spacing={2}
                    h="32px"
                    textDecoration={location.pathname === '/home/admin/backups/teacher' && 'underline'}
                  >
                    <Text color="#0e119c">Teachers&apos; request</Text>
                  </HStack>
                </Link>

                <Link
                  href="/home/admin/backups/student"
                >
                  <HStack
                    marginLeft="15px"
                    w="100%"
                    spacing={2}
                    h="32px"
                    textDecoration={location.pathname === '/home/admin/backups/student' && 'underline'}
                  >

                    <Text color="#0e119c">Students&apos; request</Text>
                  </HStack>
                </Link>
              </VStack>

              <Link
                href="/notifications"
                color="#0E119C"
                fontSize="lg"
              >
                <HStack
                  w="100%"
                  spacing={2}
                  h="32px"
                >

                  <Image src={NotificationsIconBlue} boxSize="24px" />
                  <Text>{userData.userType === 'Student' ? 'Notificações' : 'Notifications'}</Text>
                </HStack>
              </Link>
              <Text
                onClick={() => handleLogout()}
                color="#0E119C"
                fontSize="lg"
              >
                <HStack
                  w="100%"
                  spacing={2}
                  h="32px"
                >

                  <Image src={LogoutIcon} boxSize="24px" />
                  <Text>{userData.userType === 'Student' ? 'Sair' : 'Logout'}</Text>
                </HStack>
              </Text>
              <br />

              <Link href={`/home/${userData.userType.toLowerCase()}`} color="#0E119C" fontSize="lg" />

            </VStack>

          </DrawerBody>

          <DrawerFooter>
            <Link
              isExternal
              href="https://api.whatsapp.com/send?phone=5511936185850&text=Ol%C3%A1,%20vim%20pelo%20site%20e%20gostaria%20de%20ter%20mais%20informa%C3%A7%C3%B5es"
              color="#0E119C"
              fontSize="lg"
            >
              {userData.userType === 'Student' ? 'Entre em contato' : 'Contact us'}

            </Link>
            {/* <Button variant="outline" mr={3} onClick={onClose}>
              Fechar
            </Button> */}
            {/* <Button colorScheme="blue">Save</Button> */}
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}
