import { useEffect, useState } from 'react';
import CustomToastError from '../components/CustomToastError';
import api from '../services/api';
import notify from '../utils/notify';
import { useUser } from './userStore';

export function useLoading() {
  const [openLoading, setOpenLoading] = useState(false);
  const { handleClearUserData } = useUser();

  useEffect(() => {
    api.interceptors.request.use(
      (config) => {
        if (config) {
          setOpenLoading(true);
        }
        return config;
      },
      (error) => {
        if (error.response) {
          notify(
            <CustomToastError
              toastText={error.response.data || 'Unexpected error'}
            />,
          );
          setOpenLoading(false);
          return error.response;
        }
      },
    );

    api.interceptors.response.use(
      (response) => {
        setOpenLoading(false);
        return response;
      },
      (error) => {
        setOpenLoading(false);
        if (error.response) {
          if (error.response.data === 'Auth Error') {
            handleClearUserData();
            window.location.reload();
            return;
          }

          return error.response;
        }
      },
    );
  }, []);

  return {
    openLoading,
    setOpenLoading,
  };
}
