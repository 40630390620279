import { useState } from 'react';
import { useLocalStorage } from 'react-use';

export function useNotificationStore() {
  const [unreadNotifications, setUnreadNotifications, removeUnreadNotifications] = useLocalStorage('notifications-count', 0);
  const [notifications, setNotifications] = useState();
  const [selected, setSelected] = useState('all');

  localStorage.setItem('notifications-count', JSON.stringify(unreadNotifications));
  return {
    unreadNotifications,
    setUnreadNotifications,
    removeUnreadNotifications,
    setNotifications,
    notifications,
    selected,
    setSelected,
  };
}
