export function validatePassword(password) {
  const containsUL = password.toLowerCase() !== password;

  const containsLL = password.toUpperCase() !== password;

  const containsN = /\d/.test(password);

  const containsSC = /[!|@|#|$|%|&|*|(|)|-|.|_|+|=|^]/.test(password);

  const contains8C = password.length >= 8;

  return {
    containsUL,
    containsLL,
    containsN,
    containsSC,
    contains8C,
  };
}
