import { useLocalStorage } from 'react-use';

const defaultSteps = {
  stepOne: {
    selectedPlan: {
      planId: '',
      planName: '',
      planValue: 0,
    },
    selectedCardIndex: null,
  },
  stepTwo: {
    classesTime: '',
    hoursPerMonth: '',
    wantsNative: false,
    isTrial: false,
    coupon: '',
  },
  stepThree: {
    fullName: '',
    email: '',
    phone: '',
    userType: 'Student',
    acceptedPrivacy: false,
    planName: '',
  },
  stepFour: {
    userName: '',
    birthdate: '',
    cpf: '',
    isHearingImpaired: false,
    acceptedTerms: false,
  },
};

export function useStudentOnboarding() {
  const [studentRegister, setStudentRegister, removeStudentRegister] = useLocalStorage('student-register', { ...defaultSteps });

  function setStepOne(data) {
    setStudentRegister({
      ...studentRegister,
      stepOne: {
        ...data,
      },
    });
  }

  function setStepTwo(data) {
    setStudentRegister({
      ...studentRegister,
      stepTwo: {
        ...data,
      },
    });
  }

  function setStepThree(data) {
    setStudentRegister({
      ...studentRegister,
      stepThree: {
        ...data,
      },
    });
  }

  function setStepFour(data) {
    setStudentRegister({
      ...studentRegister,
      stepFour: {
        ...data,
      },
    });
  }

  function resetSteps() {
    setStudentRegister({ ...defaultSteps });
  }

  return {
    setStepOne,
    setStepTwo,
    setStepThree,
    setStepFour,
    studentRegister,
    resetSteps,
  };
}
