/* eslint-disable react/destructuring-assignment */
import { createContext, useContext } from 'react';
import { useBackup } from './backupStore';
import { usePasswordValidation } from './passwordValidationStore';
import { useUseCredits } from './planUnpauseStore';
import { useStudentOnboarding } from './studentOnboarding';
import { useTeacherOnboarding } from './teacherOnboarding';
import { useLoading } from './useLoading';
import { useNavStore } from './useNavStore';
import { useUser } from './userStore';
import { useUserDetailsStore } from './useUserDetailsStore';
import { useNotificationStore } from './useNotificationStore';
import { useGlobalStatesStore } from './globalStatesStore';

const storesCtx = createContext(null);

export function useStores() {
  return useContext(storesCtx);
}

export function StoresProvider(props) {
  const userStore = useUser();
  const studentOnboardingStore = useStudentOnboarding();
  const teacherOnboardingStore = useTeacherOnboarding();
  const loadingStore = useLoading();
  const backupStore = useBackup();
  const navStore = useNavStore();
  const userDetail = useUserDetailsStore();
  const usePasswordValidationStore = usePasswordValidation();
  const planUnpauseStore = useUseCredits();
  const notificationStore = useNotificationStore();
  const globalStatesStore = useGlobalStatesStore();

  return (
    <storesCtx.Provider value={{
      userStore,
      studentOnboardingStore,
      teacherOnboardingStore,
      loadingStore,
      navStore,
      userDetail,
      backupStore,
      usePasswordValidationStore,
      planUnpauseStore,
      notificationStore,
      globalStatesStore,
    }}
    >
      {props.children}
    </storesCtx.Provider>
  );
}
