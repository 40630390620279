import { HStack, Image, Link } from '@chakra-ui/react';
import {
  NavLink, useHistory, useLocation,
} from 'react-router-dom';
import UnmazeLogo from '../../../assets/img/unmaze/unmaze-logo.svg';
import { useStores } from '../../../stores';
import DrawerSite from '../../UserNavigation/DrawerSite';
import style from '../style.module.scss';
import TabForYou from '../Tabs/TabForYou';
import TabRegister from '../Tabs/TabRegister';

export default function NavbarSite({ children }) {
  const {
    userStore:
    { handleClearUserData, userData },
  } = useStores();

  const location = useLocation();

  const history = useHistory();

  return (
    <div className={style['main-body']}>
      <div className={style['navbar-container']}>
        <HStack w="120px" ml={10}>
          <NavLink
            to="/"
          >
            <Image w="120px" src={UnmazeLogo} alt="unmaze logo" />
          </NavLink>
        </HStack>

        <HStack
          w="100%"
          px={10}
          className={style['nav-holder']}
          justifyContent="space-between"
        >
          <HStack
            h="60px"
            justifyContent="center"
            textAlign="start"
            spacing={10}
            className={style['ghost-div']}
          >
            <NavLink
              to="/"
              exact
              className={style.inactive}
              activeClassName={style.active}
            >
              {(location.pathname === '/'
                || location.pathname === '/for-students'
                || location.pathname === '/login'
                || location.pathname === '/recovery')
                ? 'Início' : 'Home'}
            </NavLink>

            <TabForYou />

            <Link
              isExternal
              href="https://api.whatsapp.com/send?phone=5511936185850&text=Ol%C3%A1,%20vim%20pelo%20site%20e%20gostaria%20de%20ter%20mais%20informa%C3%A7%C3%B5es"
              color="#0E119C"
              className={style.inactive2}
            >
              {(location.pathname === '/'
                || location.pathname === '/for-students'
                || location.pathname === '/login'
                || location.pathname === '/recovery')
                ? 'Entre em contato' : 'Contact us'}

            </Link>
          </HStack>

          <HStack
            h="60px"
            spacing={10}
          >
            <HStack
              spacing={10}
              w="100%"
              className={style['ghost-div']}
            >
              <NavLink
                to="/"
                exact
                className={style.inactive}
                activeClassName={style.active}
              >
                {(location.pathname === '/'
                  || location.pathname === '/for-students'
                  || location.pathname === '/login'
                  || location.pathname === '/recovery')
                  ? 'Entrar' : 'Log in'}
              </NavLink>
              <TabRegister />
            </HStack>

            <HStack
              className={style.drawer}
            >
              <DrawerSite />
            </HStack>
          </HStack>

        </HStack>
      </div>

      <div className={style['main-content']}>
        <div className={style['full-page']}>
          {children}
        </div>
      </div>

    </div>
  );
}
