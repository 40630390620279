/* eslint-disable indent */
import { WarningTwoIcon } from '@chakra-ui/icons';
import {
  Center,
  HStack,
  Icon, Image, Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent, PopoverFooter, PopoverHeader, PopoverTrigger, Portal, Tag, Text,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { AiOutlineBell } from 'react-icons/ai';
import { useLocation } from 'react-use';
import api from '../../services/api';
import socket from '../../services/socket';
import { useStores } from '../../stores';
import notify from '../../utils/notify';
import CustomToastSuccess from '../CustomToastSuccess';
import NotificationCard from '../NotificationCard';
import style from './style.module.scss';
import NotificationsIcon from '../../assets/img/platform/navigation/notifications-icon.svg';
import NotificationsIconBlue from '../../assets/img/platform/navigation/notifications-icon-blue.svg';

export default function NotificationBell({ drawer }) {
  const {
    notificationStore: {
      unreadNotifications,
      setUnreadNotifications,
      setNotifications,
      notifications,
    },
    userStore: {
      userData,
      setUserData,
      token,
    },
  } = useStores();
  const [open, setOpen] = useState(false);
  const location = useLocation();

  async function getNotifications() {
    const { data } = await api.get('/notifications/unread', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    setNotifications(data);
    setUserData({ ...userData, notifications });
    setUnreadNotifications(data.length);
  }

  useEffect(() => {
    getNotifications();
  }, []);

  useEffect(() => {
    if (userData.userType === 'Student' || userData.userType === 'Teacher') {
      socket.on(`${userData.id}-new-notification`, ({ newNotifications, updatedMessage }) => {
        if (newNotifications && notifications !== newNotifications) {
          getNotifications();

          return notify(<CustomToastSuccess
            toastText={updatedMessage}
            customId="notificationId"
          />);
        }
      });
    } else {
      socket.on('admin-new-notification', ({ newNotifications, updatedMessage }) => {
        if (newNotifications && notifications !== newNotifications) {
          setNotifications({ ...notifications, notifications: newNotifications });
          getNotifications();

          return notify(<CustomToastSuccess
            toastText={updatedMessage}
            customId="notificationId"
          />);
        }
      });
    }
  }, []);

  async function markAllAsRead() {
    const response = await api.patch('/notifications/read-all', {}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    setUnreadNotifications(0);

    if (response.status === 200) {
      notifications.forEach((element, index) => {
        notifications[index].isRead = true;
        localStorage.setItem('notifications-count', unreadNotifications);
      });

      setNotifications(notifications);
      setUserData({ ...userData, notifications });
    }
  }

  async function markAllAsUnread() {
    const response = await api.patch('/notifications/unread-all', {}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 200) {
      setUnreadNotifications(notifications.length);
      notifications.forEach((element, index) => {
        notifications[index].isRead = false;
        localStorage.setItem('notifications-count', unreadNotifications);
      });
      setNotifications(notifications);
      setUserData({ ...userData, notifications });
    }
  }

  return (
    <Popover placement="bottom">
      <PopoverTrigger className={style.bell}>
        <button className={style['btn-bell']} type="button" onClick={() => setOpen(!open)}>
          {drawer ? (
            <HStack
              w="100%"
              spacing={2}
              h="36px"
            >
              <Image src={NotificationsIcon} boxSize="36px" />
              {' '}
              {unreadNotifications > 0 && (
                <div className={style.unread__drawer}>
                  {unreadNotifications >= 999
                    ? <Text>999+</Text>
                    : <Text>{unreadNotifications}</Text>}

                </div>
              )}
            </HStack>
          ) : (
            <HStack
              marginLeft={(location.pathname === '/notifications') ? 0 : '15px'}
              w="100%"
              spacing={2}
              h="32px"
            >
              {location.pathname === '/notifications'
                && <div className={style.active__marker} />}

              <Image src={userData.userType === 'Student' || userData.userType === 'Teacher' ? NotificationsIconBlue : NotificationsIcon} borderColor="blue" boxSize="24px" />
              <Text color={userData.userType === 'Student' || userData.userType === 'Teacher' ? '#06097D' : '#fff'} fontWeight={600}>
                {userData.userType === 'Student' ? 'Notificações' : 'Notifications'}
              </Text>
              {unreadNotifications > 0 && (
                <Tag
                  className={style.unread}
                  alignSelf="center"
                  bgColor="#FF1616"
                  color="white"
                  size="md"
                  fontSize="12px"
                  textAlign="center"
                  borderRadius="full"
                >
                  {unreadNotifications >= 999
                    ? '999+'
                    : `${unreadNotifications}`}

                </Tag>
              )}
            </HStack>
          )}
        </button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverHeader>
          <Text fontWeight="bold" color="#060f9b">
            {userData.userType === 'Student'
                ? 'Suas notificações'
                : 'Your notifications'}
          </Text>
        </PopoverHeader>
        <PopoverCloseButton />
        <PopoverBody w="100%" maxH="520px" overflowY="auto" p={0}>
          <Center h="25px" className={style['notifications-link']}>
            <Link
              fontWeight="bold"
              href="/notifications"
              w="100%"
              textAlign="center"
              color="#fff"
              fontSize="14px"
            >
              {userData.userType === 'Student'
                  ? 'Ir para página de notificações'
                  : 'Go to notifications page'}
            </Link>
          </Center>
          {(notifications?.length) ? (
            <div>
              {notifications.filter(
                  (notification, index) => index < 4,
                )?.map((notification, index) => (
                  <NotificationCard
                    description={notification.description}
                    creationDistance={notification.creationDistance}
                    isRead={notification.isRead}
                    type={notification.type}
                    index={index}
                    id={notification.id}
                    key={notification.id}
                  />
                ))}
            </div>
            ) : (
              <HStack h="120px" justify="center" spacing={4}>
                <WarningTwoIcon w="20px" h="20px" />
                <Text fontSize="sm">{userData.userType === 'Student' ? 'Você não possui novas notificações!' : 'You have no new notifications!'}</Text>
              </HStack>
            )}
        </PopoverBody>
        <PopoverFooter className={style.footer} p={0}>
          <HStack justify="space-between" w="100%" px={1}>
            <Link
              onClick={() => markAllAsRead()}
              color="#fff"
              fontSize="12px"
            >
              <Text>
                {userData.userType === 'Student' ? 'marcar todas como lidas' : 'mark all as read'}
              </Text>
            </Link>
          </HStack>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
}
