import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import React from 'react';
import { createRoot } from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactGA from 'react-ga4';
import Hotjar from '@hotjar/browser';
import App from './app/App';
import './index.scss';
import { StoresProvider } from './stores';

const siteId = 3768324;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);

const TRACKING_ID = 'G-F45H1DHXY5'; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const container = document.getElementById('root');
const root = createRoot(container);
const theme = extendTheme({
  styles: {
    global: {
      body: {
        fontFamily: "''Inter', 'sans-serif''",
      },
    },
    colors: {
      brand: {
        blue: '#0E119C',
        red: '#AF0202',
      },
      background: {
        white: '#FDFDFD',
      },
    },
  },
});

const tagManagerArgs = {
  gtmId: 'GTM-TF3QV5S',
};

TagManager.initialize(tagManagerArgs);

root.render(
  <React.StrictMode>
    <StoresProvider>
      <BrowserRouter>
        <ChakraProvider theme={theme}>
          <App />
        </ChakraProvider>
        <ToastContainer />
      </BrowserRouter>
    </StoresProvider>
  </React.StrictMode>,
);
