import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Avatar,
  Button, HStack, Image, Link,
  Menu, MenuButton, MenuDivider, MenuGroup, MenuItem, MenuList, Portal, Text,
} from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useLocation } from 'react-use';
import { useStores } from '../../../stores';
import style from '../style.module.scss';
import ConfigIcon from '../../../assets/img/platform/navigation/config-icon.svg';
import ConfigIconBlue from '../../../assets/img/platform/navigation/config-icon-blue.svg';

function DropdownNav({ drawer }) {
  const { userStore: { userData, handleClearUserData } } = useStores();
  const { userType, avatar } = userData;
  const history = useHistory();

  function handleLogout() {
    handleClearUserData();
    history.push('/');
  }
  const location = useLocation();

  const fullName = `${userData.firstName} ${userData.lastName}`;

  return (
    <Menu w="100%" zIndex={3222}>
      <MenuButton
        as={Button}
        variant="none"
        p={0}
        h={!drawer ? '32px' : '36px'}
        width={!drawer ? '100%' : '36px'}
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        <HStack
          marginLeft="15px"
          w="100%"
          spacing={2}
          pl={userData.userType === 'Student' || userData.userType === 'Teacher' ? 2 : 0}
          h={!drawer ? '32px' : '36px'}
          fontSize="sm"
        >
          {/* {!drawer && location.pathname === `/profile/${userData.userType.toLowerCase()}`
            && <div className={style.active__marker} />} */}

          <Image src={userData.userType === 'Student' || userData.userType === 'Teacher' ? ConfigIconBlue : ConfigIcon} boxSize={!drawer ? '24px' : '36px'} />
          {!drawer && <Text fontWeight={600}>{userData.userType === 'Student' ? 'Configurações' : 'Settings'}</Text>}
        </HStack>

      </MenuButton>
      <MenuList borderColor="rgba(14, 17, 156, 0.17)" color="black" zIndex={333}>
        <MenuGroup
          title={userType === 'Student' ? 'Conta' : 'Account'}
          textAlign="left"
        >
          <MenuItem
            w="100%"
            _hover={{ backgroundColor: 'rgba(14, 17, 156, 0.17)' }}
          >
            <NavLink to={`/profile/${userType.toLowerCase()}`} className={style['menu-item']}>
              {userType === 'Student' ? 'Meu perfil' : 'My Profile'}
            </NavLink>

          </MenuItem>
        </MenuGroup>

        <MenuDivider borderColor="rgba(14, 17, 156, 0.17)" />

        <MenuGroup
          title="Unmaze"
          textAlign="left"
        >
          <MenuItem
            w="100%"
            _hover={{ backgroundColor: 'rgba(14, 17, 156, 0.17)' }}
          >
            <Link
              className={style['menu-item']}
              isExternal
              rel="noreferrer noopener"
              href={userType === 'Student'
                ? 'https://longhaired-patient-a02.notion.site/Termos-de-Uso-para-servi-os-da-Unmaze-4da2fe139b4a47649dacd8fb4ba00d14?pvs=4'
                : 'https://longhaired-patient-a02.notion.site/Termos-de-Uso-para-servi-os-da-Unmaze-99c07b87c23b41a5893049f2cdc06472?pvs=4'}
            >
              {userType === 'Student' ? 'Termos e Condições' : 'Terms and Conditions'}

            </Link>
          </MenuItem>

          <MenuItem
            w="100%"
            _hover={{ backgroundColor: 'rgba(14, 17, 156, 0.17)' }}
          >
            <Link
              className={style['menu-item']}
              isExternal
              rel="noreferrer noopener"
              href="https://flossy-monday-28a.notion.site/Pol-tica-de-Privacidade-da-Unmaze-b8bd03bce0ee4456b116ce5145f4e595"
            >
              {userType === 'Student' ? 'Política de Privacidade' : 'Privacy Policy'}

            </Link>
          </MenuItem>

          {/* <MenuItem
            w="100%"
            _hover={{ backgroundColor: 'rgba(14, 17, 156, 0.17)' }}
          >
            <NavLink
              className={style['menu-item']}
              to="/contact"
            >
              {userType === 'Student' ? 'Contato' : 'Contact'}

            </NavLink>
          </MenuItem> */}

        </MenuGroup>

        <MenuDivider borderColor="rgba(14, 17, 156, 0.17)" />

        <MenuGroup
          title={userType === 'Student' ? 'Orientações de uso' : 'More'}
          textAlign="left"
        >
          {userType === 'Student' ? (
            <MenuItem
              w="100%"
              _hover={{ backgroundColor: 'rgba(14, 17, 156, 0.17)' }}
            >
              <NavLink
                className={style['menu-item']}
                to="/student-guidelines"
              >
                Guia do usuário

              </NavLink>
            </MenuItem>
          ) : (
            <>

              <MenuItem
                w="100%"
                _hover={{ backgroundColor: 'rgba(14, 17, 156, 0.17)' }}
              >
                <Link
                  className={style['menu-item']}
                  isExternal
                  rel="noreferrer noopener"
                  href="https://midnight-archduke-8c8.notion.site/Troubleshooting-9dac43d9f6f04e639ee5ef33f82d600a?pvs=4"
                >
                  Troubleshooting

                </Link>
              </MenuItem>

              <MenuItem
                w="100%"
                _hover={{ backgroundColor: 'rgba(14, 17, 156, 0.17)' }}
              >
                <NavLink
                  className={style['menu-item']}
                  to="/teacher-guidelines"
                >
                  User guide

                </NavLink>
              </MenuItem>

              <MenuItem
                w="100%"
                _hover={{ backgroundColor: 'rgba(14, 17, 156, 0.17)' }}
              >
                <Link
                  className={style['menu-item']}
                  isExternal
                  rel="noreferrer noopener"
                  href="https://midnight-archduke-8c8.notion.site/Rating-571ca8867a6742d8a7fba448cc5e2530?pvs=4"
                >
                  Rating

                </Link>
              </MenuItem>

              <MenuItem
                w="100%"
                _hover={{ backgroundColor: 'rgba(14, 17, 156, 0.17)' }}
              >
                <Link
                  className={style['menu-item']}
                  isExternal
                  rel="noreferrer noopener"
                  href="https://midnight-archduke-8c8.notion.site/Welcome-aboard-teacher-be0c8ca996e14b66a63c233de44db09b?pvs=4"
                >
                  Onboarding Guide

                </Link>
              </MenuItem>
            </>
          )}

        </MenuGroup>
      </MenuList>
    </Menu>
  );
}

export default DropdownNav;
