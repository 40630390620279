import { useLocalStorage } from 'react-use';

export function useBackup() {
  const [backupInfo, setBackupInfo, removeBackupInfo] = useLocalStorage('backup-info');

  function handleClearUserData() {
    removeBackupInfo();
  }

  return {
    backupInfo,
    setBackupInfo,
    handleClearUserData,
  };
}
